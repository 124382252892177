body {
  font-family: "Roboto", Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.App{
  text-align: left;
}

header {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px;
}

h1 {
  margin: 0;
}

h2 {
  margin-bottom: 20px;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  margin-bottom: 10px;
}

/* Add some style to the summary section */
.summary {
  padding: 40px 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

/* Make the skills section visually appealing */
.skills {
  background-color: #f9f9f9;
  padding: 30px 20px;
  margin-bottom: 30px;
}

.skills ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

/* Style the portfolio projects section */
.portfolio {
  padding: 30px 20px;
  margin-bottom: 30px;
}

.article {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

/* Highlight the work experience section */
.work-experience article {
  border-left: 5px solid #333;
  padding-left: 15px;
  margin-bottom: 30px;
}

/* Add a personal touch to the footer */
footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
}